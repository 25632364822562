import { GamesList } from './GamesList';
import { useRoles, userRoleIsAtLeast } from 'hooks';
import { Heading, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { GamesTabMenu } from './GamesTabMenu';
interface GamesContainerProps {
  activeFilter: string;
  assumedUserName?: string;
  currentPage: number;
  games: any[];
  isMobile: boolean;
  isPending?: boolean;
  managedGames: any[];
  numberOfRows: number;
  onGameClick?: (id: number) => void;
  onPageChange: (pageNumber: number) => void;
  onWinningsClick?: (event: any) => void;
  totalEntries: number;
}
export const GamesContainer = ({
  activeFilter,
  assumedUserName,
  currentPage,
  games,
  isMobile,
  isPending,
  managedGames,
  numberOfRows,
  onGameClick,
  onPageChange,
  onWinningsClick,
  totalEntries
}: GamesContainerProps) => {
  const userRoles = useRoles();
  const atLeastTeamManager = userRoleIsAtLeast({
    userRole: userRoles,
    requiredRole: 'team_manager'
  });
  const commonProps = {
    activeFilter,
    currentPage,
    isMobile,
    isPending,
    numberOfRows,
    onGameClick,
    onPageChange,
    onWinningsClick,
    totalEntries
  };
  if (!atLeastTeamManager) {
    return <>
        <Heading as="h2" color="text.primaryWhite" fontSize="2xl" marginBottom={8} marginTop={8} paddingX={{
        base: 0,
        lg: 2,
        xl: 6
      }}>
          My Games
        </Heading>
        <GamesList {...commonProps} games={games} managerView={false} />
      </>;
  }
  return <Tabs variant="soft-rounded" data-sentry-element="Tabs" data-sentry-component="GamesContainer" data-sentry-source-file="GamesContainer.tsx">
      <GamesTabMenu assumedUserName={assumedUserName} isMobile={isMobile} data-sentry-element="GamesTabMenu" data-sentry-source-file="GamesContainer.tsx" />
      <TabPanels data-sentry-element="TabPanels" data-sentry-source-file="GamesContainer.tsx">
        <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="GamesContainer.tsx">
          <GamesList {...commonProps} games={games} managerView={false} data-sentry-element="GamesList" data-sentry-source-file="GamesContainer.tsx" />
        </TabPanel>
        <TabPanel data-sentry-element="TabPanel" data-sentry-source-file="GamesContainer.tsx">
          <GamesList {...commonProps} games={managedGames} managerView={true} data-sentry-element="GamesList" data-sentry-source-file="GamesContainer.tsx" />
        </TabPanel>
      </TabPanels>
    </Tabs>;
};